export const AUTH_ENDPOINT = 'login'
export const FORGOT_PASSWORD_ENDPOINT = 'forgetPassword'
export const RESET_PASSWORD_ENDPOINT = 'resetPassword'
export const USERS_ENDPOINT = 'user'
export const USERS_SHOW_ENDPOINT = 'user/show'
export const USERS_SHOW_STATUS_ENDPOINT = 'user/qrStatus'
export const USERS_CREATE_ENDPOINT = 'user/create'
export const USERS_UPLOAD_FILE_ENDPOINT = 'user/upload'
export const USERS_DELETE_ENDPOINT = 'user/delete'
export const USERS_STATUS_ENDPOINT = 'user/statusUpdate'
export const EVENTS_ENDPOINT = 'event'
export const EVENTS_CREATE_ENDPOINT = 'event/create'
export const EVENTS_UPDATE_ENDPOINT = 'event/update'
export const EVENTS_SHOW_ENDPOINT = 'event/show'
export const EVENTS_DELETE_ENDPOINT = 'event/delete'
export const NOTIFICATIONS_ENDPOINT = 'notification'
export const NOTIFICATIONS_CREATE_ENDPOINT = 'notification/create'
export const NOTIFICATIONS_UPDATE_ENDPOINT = 'notification/update'
export const NOTIFICATIONS_SHOW_ENDPOINT = 'notification/show'
export const NOTIFICATIONS_DELETE_ENDPOINT = 'notification/delete'
