/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from 'react'
import { ErrorMessage, useFormikContext } from 'formik'
import Input from 'components/common/input/Input'

import ProgressBar from 'react-bootstrap/ProgressBar'
import { CloudArrowDown, PencilSimple, Trash } from 'phosphor-react'
import { agenda, speaker } from 'constants/Events'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'
import { minioSingleFileUpload } from 'containers/events/Api'
import { toast } from 'react-toastify'
import CustomToast from 'components/common/custom-toast/CustomToast'
import { alertTypes } from 'constants/Common'
import moment from 'moment'
import SpeakerModal from './SpeakerModal'
import SpeakerTable from './SpeakerTable'
import AgendaModal from './AgendaModal'
import Delete from '../../../assets/images/delete.png'

function SecondStep({ setCurrentStep, id }) {
  const formik = useFormikContext()
  const [agendaModal, setAgendaModal] = useState(false)
  const [agendaModalInitialValues, setAgendaModalInitialValues] =
    useState(agenda)
  const [speakerModal, setSpeakerModal] = useState(false)
  const [agendaCurrentIndex, setAgendaCurrentIndex] = useState(undefined)
  const [speakerModalInitialValues, setSpeakerModalInitialValues] =
    useState(speaker)
  const [fileLoading, setFileLoading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const handleOpenAgendaModal = () => {
    setAgendaModal(true)
  }

  const handleCloseAgendaModal = () => {
    setAgendaModal(false)
    setAgendaModalInitialValues(agenda)
    setAgendaCurrentIndex(undefined)
  }

  const handleOpenSpeakerModal = () => {
    setSpeakerModal(true)
  }
  const handleCloseSpeakerModal = () => {
    setSpeakerModal(false)
    setSpeakerModalInitialValues(speaker)
    setAgendaCurrentIndex(undefined)
  }
  const handlePrevious = () => {
    setCurrentStep(1)
  }

  const handleFileUpload = async (file) => {
    const formData = new FormData()
    formData.append('file', file, 'file')
    formData.append('project', 'xler')

    const fileType = file.type.split('/')[0]
    console.log(fileType)
    setFileLoading(true)

    const onProgress = (percent) => {
      setUploadProgress(percent)
    }

    const result = await minioSingleFileUpload(formData, onProgress)
    if (result?.message) {
      if (result?.paths)
        if (fileType === 'video') {
          formik.setFieldValue('gallery', [
            ...(formik.values.gallery || []),
            { imageUrl: '', vidUrl: result.paths, name: file.name },
          ])
        }

      if (fileType === 'image') {
        formik.setFieldValue('gallery', [
          ...(formik.values.gallery || []),
          { imageUrl: result.paths, name: file.name, vidUrl: '' },
        ])
      }

      setFileLoading(false)
      setUploadProgress(0)
      toast(
        <CustomToast
          variant={alertTypes.SUCCESS}
          message={result?.message || 'Successfully!'}
        />
      )
    } else {
      setFileLoading(false)
      setUploadProgress(0)
      toast(
        <CustomToast
          variant={alertTypes.DANGER}
          message={result?.response?.data?.error}
        />
      )
    }
  }
  const onDrop = useCallback(
    (files) => {
      if (files.length > 0) {
        const uploadedFiles = Array.from(files)
        uploadedFiles.forEach((file) => {
          handleFileUpload(file)
        })
      }
    },
    [formik.values?.gallery, handleFileUpload]
  )

  const removeFile = (file, type) => {
    const updatedArray = formik.values[type].filter(
      (item) => item.imageUrl !== file.imageUrl || item.vidUrl !== file.vidUrl
    )
    formik.setFieldValue(type, updatedArray)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/svg+xml, image/png, image/jpeg, image/gif, video/*',
    maxSize: 50 * 1024 * 1024,
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((rejection) => {
        rejection.errors.forEach((error) => {
          if (error.code === 'file-too-large') {
            toast(
              <CustomToast
                variant={alertTypes.DANGER}
                message={`File too large: ${rejection.file.name} please upload file size less than 50mb`}
              />
            )
          }
        })
      })
    },
  })
  console.log('formik errors ', formik.values)
  return (
    <div className='row'>
      <div className='container-fluid'>
        <div className='table-otr'>
          <table className='table table-main'>
            <thead>
              <tr>
                <th scope='col'>
                  <div className='header-text-otr'>
                    <p
                      className='table-name heading-xsb'
                      aria-label='User index Column'
                    >
                      Title
                    </p>
                  </div>
                </th>
                <th scope='col'>
                  <div className='header-text-otr'>
                    <p
                      className='table-name heading-xsb'
                      aria-label='User Name Column'
                    >
                      Description
                    </p>
                  </div>
                </th>
                <th scope='col'>
                  <div className='header-text-otr'>
                    <p
                      className='table-name heading-xsb'
                      aria-label='User Name Column'
                    >
                      Start Date
                    </p>
                  </div>
                </th>
                <th scope='col'>
                  <div className='header-text-otr'>
                    <p
                      className='table-name heading-xsb'
                      aria-label='User Name Column'
                    >
                      End Date
                    </p>
                  </div>
                </th>
                <th scope='col' className='action-column'>
                  <div className='header-text-otr'>
                    <p
                      className='table-name heading-xsb'
                      aria-label='User Action Name Column'
                    >
                      Actions
                    </p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {formik.values?.agenda?.map((item, index) => (
                <tr key={item._id}>
                  <th scope='col'>
                    <div className='header-text-otr'>
                      <p
                        className='table-name heading-xsb'
                        aria-label='User Table Data Column'
                      >
                        {item.title}
                      </p>
                    </div>
                  </th>
                  <td>
                    <div className='table-text-otr'>
                      <p className='table-text-black' title={item.description}>
                        {item.description}
                      </p>
                    </div>
                  </td>

                  <td>
                    <div className='table-text-otr'>
                      {item.startDate && (
                        <p className='table-text-black'>
                          {moment(item.startDate).format('MMMM D, YYYY h:mm a')}
                        </p>
                      )}
                    </div>
                  </td>

                  <td>
                    <div className='table-text-otr'>
                      {item.endDate && (
                        <p className='table-text-black'>
                          {moment(item.endDate).format('MMMM D, YYYY h:mm a')}
                        </p>
                      )}
                    </div>
                  </td>

                  <td className='action-column'>
                    <div className='table-icon-otr'>
                      <div
                        className='icon-otr'
                        role='button'
                        tabIndex={0}
                        aria-label='Edit User'
                      >
                        <PencilSimple
                          className='primary-color'
                          size={18}
                          onClick={() => {
                            setAgendaCurrentIndex(index)
                            setAgendaModalInitialValues(item)
                            handleOpenAgendaModal()
                          }}
                        />
                      </div>
                      <div
                        className='icon-otr'
                        role='button'
                        tabIndex={0}
                        aria-label='Delete User'
                      >
                        <Trash
                          className='danger-color'
                          size={18}
                          onClick={() => {
                            const updatedAgenda = [...formik.values.agenda]
                            updatedAgenda.splice(index, 1) // Remove the item at the specified index
                            formik.setFieldValue('agenda', updatedAgenda)
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={5}>
                  <div className='table-text-otr d-flex flex-column align-items-center'>
                    <button
                      type='button'
                      className='primary-btn mt-4 mb-2'
                      onClick={handleOpenAgendaModal}
                    >
                      Add Agenda
                    </button>
                    <ErrorMessage
                      className='error-text'
                      component='p'
                      name='agenda'
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='container-fluid'>
        <SpeakerTable
          formik={formik}
          handleOpenSpeakerModal={handleOpenSpeakerModal}
          setSpeakerModalInitialValues={setSpeakerModalInitialValues}
          setCurrentIndex={setAgendaCurrentIndex}
          handleCloseSpeakerModal={handleCloseSpeakerModal}
        />
      </div>
      <div className='col-md-12 mt-5'>
        <p className='heading-smb'>Gallery</p>
      </div>

      <div className='col-md-12'>
        <div className='d-flex'>
          <div className='w-25'>
            <Input
              name='galleryInput'
              value={formik.values.galleryInput}
              handleChange={formik.handleChange}
              placeholder='Link'
              label='Enter Link'
            />
          </div>
          <button
            type='button'
            style={{ lineHeight: '23px' }}
            className='align-self-baseline btn primary-btn ms-4 mt-5'
            onClick={() => {
              if (formik.values.galleryInput) {
                const newLink = {
                  link: formik.values.galleryInput,
                  name: formik.values.galleryInput,
                }

                formik.setFieldValue(
                  'gallery',
                  formik.values.gallery?.length > 0
                    ? [...formik.values.gallery, newLink]
                    : [newLink]
                )
                formik.setFieldValue('galleryInput', '')
              }
            }}
          >
            Attach
          </button>
        </div>

        <h5 className='text-secondary ms-1'>OR</h5>

        {fileLoading ? (
          <div className='image-upload flex-column'>
            <p className='ms-4'>Please wait for the File to upload!</p>
            <ProgressBar
              className='w-75'
              now={uploadProgress}
              label={`${uploadProgress}%`}
            />
          </div>
        ) : (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className='image-upload'>
              <CloudArrowDown size={32} />
              <p>
                Clicks to upload or drag and drop <br />
                <span>SVG, PNG, JPG, VIDEO or GIF (max. 50mb)</span>
              </p>
            </div>
          </div>
        )}
        <ErrorMessage className='error-text' component='p' name='gallery' />
        <ul>
          {formik.values?.gallery?.map((file) => {
            let fileUrl = ''
            let fileLabel = ''

            if (file.imageUrl) {
              fileUrl = file.imageUrl
              fileLabel = `Image: `
            } else if (file.vidUrl) {
              fileUrl = file.vidUrl
              fileLabel = `Video: `
            } else if (file.link) {
              fileUrl = file.link
              fileLabel = `Link: `
            }

            return (
              <li key={fileUrl}>
                {fileLabel}
                <a
                  style={{ display: 'inline' }}
                  download={fileUrl} // This is the file to be downloaded
                  href={fileUrl}
                  target='_blank'
                  rel='noopener noreferrer' // The file's URL
                >
                  {fileUrl}
                </a>
                <button
                  onClick={() => removeFile(file, 'gallery')}
                  type='button'
                  className='btn'
                >
                  <img width={20} src={Delete} alt='delete' />
                </button>
              </li>
            )
          })}
        </ul>
      </div>

      {agendaModal && (
        <AgendaModal
          handleCloseModal={handleCloseAgendaModal}
          formik={formik}
          index={agendaCurrentIndex}
          initialValues={agendaModalInitialValues}
        />
      )}
      {speakerModal && (
        <SpeakerModal
          handleCloseModal={handleCloseSpeakerModal}
          formik={formik}
          index={agendaCurrentIndex}
          initialValues={speakerModalInitialValues}
        />
      )}
      <div className='col-md-12 d-flex justify-content-end gap-4 mt-5 mb-4'>
        <button
          type='button'
          className='secondary-btn record-btn'
          onClick={handlePrevious}
        >
          Previous
        </button>
        <button type='submit' className='primary-btn record-btn'>
          {id ? 'Update' : 'Create'}
        </button>
      </div>
    </div>
  )
}
SecondStep.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
}
export default SecondStep
